import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, Result, message } from 'antd';
import './accessKeyManagement.less';
import { getAccessKeyManagementList, CreateAccessKey, deleteAccessKey } from '../../../api'
import { useRequest, useUpdateEffect } from 'ahooks'
import { useRecoilValue } from 'recoil'
import { userInfo as recoilUserInfo, appReady } from '../../../recoil'
import moment from 'moment';
import type { FormValuesType } from './accessKeyManagementData';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useI18nHook } from '../../../i18n';


function AccessKeyManagement() {
  const [data, setData] = useState<Array<AccessKeyManagementType.AccessKeyManagement>>([])
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const userInfo = useRecoilValue(recoilUserInfo);
  const appIsReady = useRecoilValue(appReady);
  const [secretDownloadVisible, setSecretDownloadVisible] = useState(false)
  const [secret, setSecret] = useState<string>('')
  const [secretID, setSecretID] = useState<string>('')
  const [displayName, setDisplayName] = useState<string>('secret')
  // 国际化
  const i = useI18nHook()

  const { run: runCreateAccessKey } = useRequest(CreateAccessKey, {
    manual: true,
    onSuccess: (data) => {
      setSecret(data.secret)
      setSecretID(data.id)
      setSecretDownloadVisible(true)
    },
    onError: (err) => {
      console.log('error')
      console.log(err)
      message.error(i('创建失败！'))
    }
  });

  useEffect(() => {
    if (userInfo && userInfo.person)
      runGetAccessKeyManagementList()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    if (appIsReady)
      runGetAccessKeyManagementList()
  }, [appIsReady]);

  const { run: runGetAccessKeyManagementList, loading } = useRequest(getAccessKeyManagementList, {
    manual: true,
    onSuccess: (res) => {
      if (res) {
        setData(res.openAccessKeys)
      }
    }
  })

  const onFinish = (values: FormValuesType) => {
    setCreateModalVisible(false)
    setDisplayName(values.displayName)
    runCreateAccessKey({
      ...values,
      // moment.defaultFormat -> RFC3339 => YYYY-MM-DDTHH:mm:ssZ
      expireTime: values.expireTime.utc().format(moment.defaultFormat)
    })

  };

  const disabledDate = (current: moment.Moment) => {
    return (current && current < moment().endOf('day')) || current > moment(new Date('01/01/2031'));
  }

  function range(start:number, end:number) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  

  const disabledTime = (current: moment.Moment | null) => {
    if(current){
      return {
        disabledHours: () => range(0, current.hour()+1)
      };
    }

    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  }

  const downloadTxt = (text: string, fileName: string) => {
    let element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', fileName)
    element.style.display = 'none'
    element.click()
  }

  const handleCloseSecretDownload = () => {
    Modal.confirm({
      title: i('确定要关闭吗'),
      icon: <ExclamationCircleOutlined />,
      content: i('我们不会保存您的secret请确保已经备份，否则将无法恢复'),
      okText: i('确定'),
      cancelText: i('取消'),
      onOk() {
        runGetAccessKeyManagementList()
        setSecretDownloadVisible(false)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  const SecretDownload = () => {
    return (
      <Modal title={i("创建成功")} visible={secretDownloadVisible} closable={false} footer={false}>
        <Result
          status="warning"
          title={i("创建成功！请在下方链接下载您的secret")}
          subTitle={i("请注意我们不会保存您的secret，下方链接是您唯一获取secret的方式，请妥善保管！")}
          extra={
            <div>
              <Button danger style={{ marginBottom: '10px' }} onClick={() => { downloadTxt(`AccessKeyID, AccessKeySecret\r\n${secretID},${secret}`, displayName) }}><span>{i("下载secret")}</span></Button>
              <br />
              <Button type="primary" onClick={handleCloseSecretDownload} key="console">
                {i("关闭")}
              </Button>
            </div>
          }
        />,
      </Modal>
    )
  }

  const onDelete = async (openAccessKeyId: string) => {
    try {
      const res = await deleteAccessKey({openAccessKeyId})
      if(res.code === 0) {
        message.success(i("删除成功"))
        runGetAccessKeyManagementList()
      } else {
        const errorCodes: any = {
          13: i("服务异常，请重试"),
          20000: i("无法删除非自己创建的应用")
        }
        message.error(errorCodes[res.code] || "出错啦")
      }
    } catch (error: any) {
      message.error(i(`删除失败: ${error.message}`))
    }
  }

  const confirmDelete = (openAccessKeyId: string) => {
    Modal.confirm({
      title: i("提示"),
      content: i("您是否确认删除，会影响已有的开发对接成果？"),
      onOk: () => onDelete(openAccessKeyId),
      okText: i("确定"),
      cancelText: i("取消"),
      onCancel: () => Modal.destroyAll()
    })
  }

  const columns: any = [
    {
      title: 'AccessKeyId',
      dataIndex: 'id',
    },
    {
      title: i('名称'),
      dataIndex: 'displayName',
    },
    {
      title: i('描述'),
      dataIndex: 'description',
    },
    {
      title: i('状态'),
      dataIndex: 'state',
      width: '100px',
      render: (text: string, record: AccessKeyManagementType.AccessKeyManagement) => {
        return (
          <>{moment(record.expireTime, moment.defaultFormat).valueOf() - new Date().valueOf() > 0 ? i('已启用') : i('已过期')}</>
        )
      }
    },
    {
      title: i('创建时间'),
      dataIndex: 'createTime',
      width: '200px',
      render: (text: string)=>{
        return (
          <>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</>
        )
      }
    },
    {
      title: i('过期时间'),
      dataIndex: 'expireTime',
      width: '200px',
      render: (text: string)=>{
        return (
          <>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</>
        )
      }
    },
    {
      title: i("操作"),
      key: "operation",
      width: "200px",
      fixed: 'right',
      render: (row: any) => {
        return (
          <>
            <Button type='link' onClick={() => confirmDelete(row.id)}>{i("删除")}</Button>
          </>  
        )
      }
    }
  ];

  const CreateAccessKeyModal = () => {
    return (
      <Modal title={i("创建AccessKey")} maskClosable={false} visible={createModalVisible} footer={false} onCancel={() => { setCreateModalVisible(false) }}>
        <Form
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          labelWrap={true}
        >
          <Form.Item
            label={i("名称")}
            name="displayName"
            rules={[{ required: true, message: i('请填写当前创建的AccessKey的别名！') },
            { pattern: /\S/, message: i('请填写当前创建的AccessKey的别名！')}
            ]}
          >
            <Input showCount maxLength={100}/>
          </Form.Item>

          <Form.Item
            label={i("过期时间")}
            name="expireTime"
            rules={[{ type: 'object' as const, required: true, message: i('请选择时间') }]}
            initialValue={moment(new Date()).add(1, 'years')}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              placeholder={i('请选择日期')}
              showTime={{ defaultValue: moment(new Date()).add(1, 'years') }}
              showNow={false}
            />

          </Form.Item>

          <Form.Item
            label={i("描述")}
            name="description"
            rules={[{ required: true, message: i('请填写当前创建的AccessKey的描述！') },
            { pattern: /\S/, message: i('请填写当前创建的AccessKey的描述！') }]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 11, span: 13 }}>
            <Button type="primary" htmlType="submit">
              {i("提交")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const handleCreate = () => {
    setCreateModalVisible(true)
  }
  return (
    <div className='main'>
      <SecretDownload />
      <CreateAccessKeyModal />
      <div style={{ marginBottom: 16 }}>
        <Button onClick={handleCreate} style={{ marginRight: '5px' }} type="primary">
          {i("创建")}
        </Button>
        {/* <Button onClick={() => { setSecretDownloadVisible(true) }} style={{ marginRight: '5px' }} type="primary">
          test
        </Button> */}
        <Button onClick={runGetAccessKeyManagementList} type="primary">
         {i("刷新列表")}
        </Button>
      </div>
      <Table rowKey='id' columns={columns} loading={loading} dataSource={data} pagination={false} scroll={{ y: 999999 }} />
    </div>
  );
}

export default AccessKeyManagement