import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider ,Spin} from 'antd';
import {
  RecoilRoot
} from 'recoil';
import zhCN from 'antd/lib/locale-provider/zh_CN';


ReactDOM.render(
  <RecoilRoot>
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <Suspense fallback={<div style={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}><Spin /></div>}>
          <App />
        </Suspense>
      </BrowserRouter>
    </ConfigProvider>
  </RecoilRoot>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
